var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"validationPushRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"head-card"},[_c('div',{staticClass:"head-card-top"},[_c('h4',[_vm._v("Notification content")])]),_c('hr')]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('web-push.title'),"label-for":"i-push-title"}},[_c('validation-provider',{attrs:{"name":_vm.$t('web-push.title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length == 0 || 'is-invalid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LayoutIcon"}})],1),_c('b-form-input',{attrs:{"id":"i-push-title","placeholder":"Push title","state":errors.length > 0 ? false:null,"autocomplete":"title"},model:{value:(_vm.webpush.title),callback:function ($$v) {_vm.$set(_vm.webpush, "title", $$v)},expression:"webpush.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('web-push.message'),"label-for":"i-push-message"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('b-form-textarea',{attrs:{"id":"i-push-message","placeholder":_vm.$t('web-push.message')},model:{value:(_vm.webpush.message),callback:function ($$v) {_vm.$set(_vm.webpush, "message", $$v)},expression:"webpush.message"}})],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('web-push.url'),"label-for":"i-push-url"}},[_c('validation-provider',{attrs:{"name":_vm.$t('web-push.url'),"rules":{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length == 0 || 'is-invalid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LinkIcon"}})],1),_c('b-form-input',{attrs:{"id":"i-push-url","placeholder":"Url (redirect after click)","state":errors.length > 0 ? false:null,"autocomplete":"domain"},model:{value:(_vm.webpush.url),callback:function ($$v) {_vm.$set(_vm.webpush, "url", $$v)},expression:"webpush.url"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('web-push.image'),"label-for":"i-push-image"}},[_c('validation-provider',{attrs:{"name":_vm.$t('web-push.image'),"rules":{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length == 0 || 'is-invalid'},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"ImageIcon"}})],1),_c('b-form-input',{attrs:{"id":"i-push-image","placeholder":"Push image Url","state":errors.length > 0 ? false:null,"autocomplete":"domain"},model:{value:(_vm.webpush.image_url),callback:function ($$v) {_vm.$set(_vm.webpush, "image_url", $$v)},expression:"webpush.image_url"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('PushNotificationPreview',{attrs:{"webpush":_vm.webpush}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","disabled":invalid || _vm.webpushButtonDisabled},on:{"click":function($event){return _vm.sendPush(invalid)}}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('form.send')))]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"SendIcon"}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }