<template>
  <b-form @submit.prevent>
    <validation-observer
      v-slot="{ invalid }"
      ref="validationPushRules"
    >
      <div class="head-card">
        <div class="head-card-top">
          <h4>Notification content</h4>
        </div>
        <hr>
      </div>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('web-push.title')"
            label-for="i-push-title"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('web-push.title')"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length == 0 || 'is-invalid'"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="LayoutIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-push-title"
                  v-model="webpush.title"
                  placeholder="Push title"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="title"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('web-push.message')"
            label-for="i-push-message"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="FileTextIcon" />
              </b-input-group-prepend>
              <b-form-textarea
                id="i-push-message"
                v-model="webpush.message"
                :placeholder="$t('web-push.message')"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="$t('web-push.url')"
            label-for="i-push-url"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('web-push.url')"
              :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length == 0 || 'is-invalid'"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="LinkIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-push-url"
                  v-model="webpush.url"
                  placeholder="Url (redirect after click)"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="domain"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('web-push.image')"
            label-for="i-push-image"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('web-push.image')"
              :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length == 0 || 'is-invalid'"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="ImageIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-push-image"
                  v-model="webpush.image_url"
                  placeholder="Push image Url"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="domain"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <PushNotificationPreview :webpush="webpush" />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          class="mt-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="invalid || webpushButtonDisabled"
            @click="sendPush(invalid)"
          >
            <span class="align-middle">{{ $t('form.send') }}</span>
            <feather-icon
              icon="SendIcon"
              class="ml-50"
            />
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-form>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PushNotificationPreview from './PushNotificationPreview.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    PushNotificationPreview,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    site: {
      type: Object,
      default: () => '',
    },
    notificationConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      webpush: {
        site_name: '',
        title: '',
        message: '',
        url: '',
        image_url: '',
        icon_url: '',
      },
      webpushButtonDisabled: false,
    }
  },
  created() {
    this.webpush.site_name = this.site.name
  },
  methods: {
    async sendPush(isInvalid) {
      if (isInvalid) {
        return
      }

      this.$refs.validationPushRules.validate().then(async success => {
        if (success) {
          this.webpushButtonDisabled = true
          const myHeaders = new Headers()
          myHeaders.append('Content-Type', 'application/json')

          const raw = JSON.stringify({
            siteId: this.$route.params.siteId,
            title: this.webpush.title,
            message: this.webpush.message,
            url: this.webpush.url,
            image: this.webpush.image_url,
          })

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          }

          await fetch('https://phoenix-widget.com/wp/send-notification', requestOptions)
            .then(response => response.json())
            .then(result => {
              if (result.status === 'success') {
                setTimeout(() => {
                  this.webpushButtonDisabled = false
                }, 3000)
              }

              if (result.status === 'error') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: result.message,
                  },
                })
                setTimeout(() => {
                  this.webpushButtonDisabled = false
                }, 3000)
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            })
        } else {
          this.webpushButtonDisabled = true
        }
      })
    },
  },
}
</script>
