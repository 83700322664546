<template>
  <b-card class="integration-card">
    <app-collapse>
      <app-collapse-item title="Integration">
        <ol>
          <li>
            <p><span>Add the following code to the main HTML template of your website, anywhere above the closing tag &lt;/head&gt;</span></p>
            <p>
              <b-form-textarea
                ref="tarea"
                v-model="headScript"
                variant="primary"
                placeholder="Code"
                rows="2"
                disabled
              />
              <b-button
                v-clipboard:copy="headScript"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-b-tooltip.hover.top="$t('widgets.copybutton')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
              >
                <feather-icon icon="CopyIcon" /> {{ $t('form.copy') }}
              </b-button>
            </p>
          </li>
          <li>
            <p>Download/extract .ZIP file (contains manifest.json and push-worker.js). Copy them into the root folder of your website. Extract SDK files (manifest.json and push-worker.js) from the archive, add them to the root folder of your website.</p>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-4"
            >
              Download push.zip
            </b-button>
          </li>
          <li>
            <p>Verify integration - go to your site and subscribe to notifications.</p>
          </li>
        </ol>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>
<script>

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BButton,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BFormTextarea,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      test: '',
    }
  },
  computed: {
    headScript() {
      const { siteId } = this.$route.params
      return `<script src="https://phoenix-widget.com/static/js/web-push/pnx-wp.js?v=1.4" data-site_id="${siteId}"><${''}/script>`
    },
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Widget code copied',
          icon: 'BellIcon',
        },
      })
      this.$refs.tarea.focus()
      this.$refs.tarea.select()
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy widget code!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
<style scoped>
.integration-card .card-body{
  padding: 4px;
}
</style>
