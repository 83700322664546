<template>
  <div>
    <div class="preview-push">
      <div class="preview v7">
        <div class="push-control" />
        <div class="push-content">
          <div class="push-icon">
            <b-img
              v-bind="iconProps"
              fluid
              rounded
              :src="previewData.image"
              :alt="previewData.site_name"
            />
          </div>
          <div class="push-title">
            <p>{{ previewData.title }}</p>
          </div>
          <div class="push-text">
            <p>{{ previewData.text }}</p>
          </div>
          <div class="push-url">
            <p>
              <svg
                width="12"
                height="12"
              >
                <use
                  :xlink:href="getIconHref('icon-browser-yandex')"
                />
              </svg>
              <span>Chrome • {{ previewData.site_name }}</span></p>
          </div>
        </div>
      </div>
      <p class="preview-warning">
        *Notification preview. Depending on the OS and/or browser, you may notice minor differences. Be sure to preview for each OS and/or browser.
      </p>
    </div>
  </div>
</template>

<script>

import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    previewData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      iconProps: {
        width: 40,
        height: 40,
      },
    }
  },
  methods: {
    getIconHref(iconName) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, global-require
      const icon = require('@/assets/images/webpush/sprite.svg')
      return `${icon}#${iconName}`
    },
  },
}
</script>
<style scoped>
.preview-push {
  box-sizing: content-box;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px 16px;
}

.preview {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .1);
    text-align: left;
    background-color: #fff;
}

.preview.v7 {
    width: 350px;
}

.push-icon {
  position: relative;
}

.preview.v7 .push-icon {
    float: right;
    margin-left: 14px;
    margin-bottom: 6px;
    border-radius: 4px;
    overflow: hidden;
    line-height: 0;
}

.preview.v7 .push-content {
    padding: 32px 24px 12px;
    overflow: hidden;
}

.push-url {
  grid-area: url;
}

.preview.v7 .push-url  {
    position: absolute;
    top: 12px;
    left: 24px;
    right: 24px;
}

.preview.v7 .push-url p {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 15px;
    color: #8c8c8c;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.preview.v7 .push-url svg {
    margin-right: 4px;
    fill: #8c8c8c;
}

.preview.v7 .push-title {
  margin-bottom: 4px;
}

.preview.v7 .push-title p {
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: #252525;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
}

.preview.v7 .push-text {
  line-height: 16px;
}

.preview.v7 .push-text p {
    display: inline;
    font-size: 13px;
    line-height: inherit;
    color: #515151;
}

.preview-warning {
    text-align: center;
    margin-bottom: 0;
    margin-top: 16px;
    color: hsla(0, 0%, 30%, 0.6);
    font: 12px / 14px Lato, SourceSansPro, sans-serif;
}
</style>
